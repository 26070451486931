import { CallToAction } from './components/CallToAction';
import { Navbar } from './components/Navbar';
import hero from './img/heroImage.png';
import { Waypoint } from 'react-waypoint';
import { useState } from 'react';
import { NavbarSticky } from './components/Navbar-Sticky';
import { About } from './components/About';
import { News } from './components/News';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { GiftCards } from './components/GiftCards';
import { Yelp } from './components/Yelp';

function App() {
  const [showNav, setShowNav] = useState(false);

  return (
    <div>
      {/* <div className='flex items-center justify-center px-6 py-6 text-center text-white bg-black h-14 opacity-90'>We are open on Mother's Day!<br />Sunday, May 8th from 11:30 AM - 9:
        
         00 PM.</div> */}
      <div
        className='h-screen bg-scroll bg-center bg-cover font-lato md:bg-fixed'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url(${hero})`,
        }}
      >
        <div className='md:max-w-6xl md:flex md:flex-col md:m-auto'>
          <header className='h-screen'>
            <Navbar />
            <CallToAction />
            <Waypoint onLeave={() => setShowNav(true)} />
            <Waypoint onEnter={() => setShowNav(false)} />
          </header>
          {showNav && <NavbarSticky />}
          <About />
          {/* <div
            className='flex flex-col items-center justify-center mt-4 sm:flex-row sm:gap-5 md:block md:mt-6'
            id='reserve'
          >
            <Yelp />
          </div> */}
          <News />
          <GiftCards />
          <Contact />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
